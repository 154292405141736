import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sdrw-content',
  templateUrl: './sdrw-content.component.html',
  styleUrls: ['./sdrw-content.component.scss']
})
export class SdrwContentComponent implements OnInit {
  @Input() size: 'full' | 'responsive' = 'responsive';

  constructor() { }

  ngOnInit() {
  }

}
