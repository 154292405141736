<app-sdrw-navbar></app-sdrw-navbar>
<app-sdrw-content>

        <h1 *ngIf="isDeveloper">Welcome to the SideDrawer Dashboard!</h1>
        <h1 *ngIf="isManager">Welcome to the SideDrawer Dashboard!</h1>
        <h1 *ngIf="isEditor">Welcome to the SideDrawer Back Office!</h1>
        <h1 *ngIf="isAdmin">Welcome to the SideDrawer Back Office!</h1>

        <app-sdrw-menu [data]="buttons"></app-sdrw-menu>

</app-sdrw-content>