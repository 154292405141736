import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdrwNavbarComponent } from './sdrw-navbar/sdrw-navbar.component';
import { SdrwContentComponent } from './sdrw-content/sdrw-content.component';
import { SdrwTableComponent } from './sdrw-table/sdrw-table.component';
import { SdrwFilterListComponent } from './sdrw-filter-list/sdrw-filter-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecordTypeFormComponent } from './record-type-form/record-type-form.component';
import { SdrwMenuComponent } from './sdrw-menu/sdrw-menu.component';
import { RouterModule } from '@angular/router';



@NgModule({
  entryComponents: [
    SdrwNavbarComponent,
    SdrwContentComponent,
    SdrwTableComponent,
    SdrwFilterListComponent,
    RecordTypeFormComponent,
    SdrwMenuComponent
  ],
  declarations: [
    SdrwNavbarComponent,
    SdrwContentComponent,
    SdrwTableComponent,
    SdrwFilterListComponent,
    RecordTypeFormComponent,
    SdrwMenuComponent
  ],
  exports: [
    SdrwNavbarComponent,
    SdrwContentComponent,
    SdrwTableComponent,
    SdrwFilterListComponent,
    RecordTypeFormComponent,
    SdrwMenuComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule
  ]
})
export class ComponentsModule { }
