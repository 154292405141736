import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError, from, combineLatest, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { mergeMap, catchError, map, tap, flatMap, concatMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return this.auth.getTokenSilently$().pipe(
      concatMap(token => {

        const tokenReq = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });

        return next.handle(tokenReq);

      })
    );

  }

}
