import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sdrw-navbar',
  templateUrl: './sdrw-navbar.component.html',
  styleUrls: ['./sdrw-navbar.component.scss']
})
export class SdrwNavbarComponent implements OnInit {

  constructor(public auth: AuthService, public router: Router) { }

  ngOnInit() {
  }


  goToHome() {
    this.router.navigateByUrl('/');
  }

}
