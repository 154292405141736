import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { MfaGuard } from './core/guards/mfa.guard';
import { UserGuard } from './core/guards/user.guard';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';


const routes: Routes = [
  {
    path: '', component: DashboardHomeComponent, pathMatch: 'full',
    canActivate: [AuthGuard, UserGuard],
    data: {
      roles: ['Editor', 'Admin', 'Owner', 'Manager']
    },
  },
  {
    path: 'sidedrawer-type/:sidedrawerType/record-types',
    canActivate: [AuthGuard, MfaGuard, UserGuard],
    data: {
      roles: ['Editor', 'Admin']
    },
    loadChildren: () => import('./records/records.module').then(m => m.RecordsModule)
  },
  {
    path: 'config',
    canActivate: [AuthGuard, UserGuard],
    data: {
      roles: ['Editor', 'Admin']
    },
    loadChildren: () => import('./config/config.module').then(m => m.ConfigModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard, MfaGuard, UserGuard],
    data: {
      roles: ['Admin']
    },
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'mfa',
    canActivate: [AuthGuard],
    loadChildren: () => import('./mfa/mfa.module').then(m => m.MfaModule)
  },
  {
    path: 'content',
    canActivate: [AuthGuard, MfaGuard, UserGuard],
    data: {
      roles: ['Editor', 'Admin']
    },
    loadChildren: () => import('./config/content/content.module').then(m => m.ContentModule)
  },
  {
    path: 'tenants',
    canActivate: [AuthGuard, UserGuard],
    data: {
      roles: ['Owner', 'Manager', 'Admin']
    },
    loadChildren: () => import('./tenants/tenants.module').then(m => m.TenantsModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
