import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwtDecode from 'jwt-decode';
import { AuthService } from '../core/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent implements OnInit {
  buttons = [];
  editorButtons = [
    { link: 'sidedrawer-type/individual/record-types', text: 'Tiles, Suggestions and Additional Information Fields' },
    { link: '/config', text: 'Messaging Templates' }
  ];
  adminButtons = [
    { link: '/users', text: 'Users, Subscriptions and Licenses' },
    { link: '/content', text: 'Models, Dictionaries and Collections' },
  ];

  isAdmin = false;
  isManager = false;
  isEditor = false;
  isDeveloper = false;


  developerButtons = [
    { link: '/tenants', text: 'Tenant Management' },

    /*{ link: '/tenant-users', text: 'Users ( Sidedrawer and Record navigation)' },
    { link: '/teams', text: 'Team Management' },
    { link: '/invitations', text: 'Invitations' },*/

  ];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private cookieService: CookieService) { }

  ngOnInit() {
    this.getUserRole();
  }

  async getUserRole() {
    const token = await this.auth.getTokenSilently$().toPromise();

    const user = jwtDecode(token);
    const KEY_TO_GET_ROLE = 'https://sidedrawer.com/roles';
    const isAdmin = (user[KEY_TO_GET_ROLE].includes('Admin'));
    const isDeveloper = (user[KEY_TO_GET_ROLE].includes('Owner'));
    const isEditor = (user[KEY_TO_GET_ROLE].includes('Editor'));
    const isManager = (user[KEY_TO_GET_ROLE].includes('Manager'));

    this.isAdmin = isAdmin;
    this.isDeveloper = isDeveloper;
    this.isEditor = isEditor;
    this.isManager = isManager;

    if (isAdmin) {
      this.buttons.push(...this.adminButtons, ...this.editorButtons);
    } else if (isDeveloper || isManager) {
      this.buttons.push(...this.developerButtons);
    } else if (isEditor) {
      this.buttons.push(...this.developerButtons);

    }
  }

}
