import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EmbeddedUserSearchComponent } from './embedded-user-search/embedded-user-search.component';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { PlansRequestFormComponent } from './record-type-form/plans-requests-form.component';

@NgModule({
    entryComponents: [
        EmbeddedUserSearchComponent,
        PlansRequestFormComponent
    ],
    declarations: [
        EmbeddedUserSearchComponent,
        PlansRequestFormComponent
    ],
    exports: [
        EmbeddedUserSearchComponent,
        PlansRequestFormComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule
    ]
})
export class TenantComponentsModule { }
