import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RecordsInterface } from 'src/app/records/records.interface';
import { SidedrawerType } from '../enums/sidedrawer-types.enum';

export const RECORDTYPES_API_URL = `${environment.API_URL.TENANTS}`;

@Injectable({
  providedIn: 'root'
})
export class RecordsService {

  constructor(private http: HttpClient) { }

  getAll(filters: any, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    return this.http.get<Array<RecordsInterface>>(RECORDTYPES_API_URL + `/tenant/tenant-id/${tenantId}/records-type`, {
      params: filters
    });
  }

  getCustomTypes( tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    return this.http.get<Array<string>>(RECORDTYPES_API_URL + `/tenant/tenant-id/${tenantId}/records-type/sidedrawer-types`, {});
  }

  get(recordName: string, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    return this.http.get<RecordsInterface>
      (`${RECORDTYPES_API_URL}/tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordName}`);
  }

  create(record: RecordsInterface, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    return this.http.post(RECORDTYPES_API_URL + `/tenant/tenant-id/${tenantId}/records-type`, record);
  }

  delete(recordName: string, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    return this.http.delete(`${RECORDTYPES_API_URL}/tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordName}`);
  }

  update$(recordName: string, body: RecordsInterface, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    return this.http.put(`${RECORDTYPES_API_URL}/tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordName}`, body);

  }
}
