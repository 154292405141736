import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import * as jwtDecode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private auth: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve, reject) => {
      const roles = next.data.roles;
      const token = await this.auth.getTokenSilently$().toPromise();
      const user = jwtDecode(token);
      const KEY_TO_GET_ROLE = 'https://sidedrawer.com/roles';
      let hasRole = false;
      roles.map((role) => {
        if (!hasRole) {
          hasRole = (user[KEY_TO_GET_ROLE].includes(role));
        }
      });

      if (!hasRole) {
        this.auth.login();
      }

      resolve(hasRole);
    });
  }

}
