import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RECORDTYPES_API_URL } from './records.service';

@Injectable({
  providedIn: 'root'
})
export class RecordSubtypesService {

  private API_URL = `${RECORDTYPES_API_URL}/record-type-name`;
  private SUBTYPES_ROUTE = 'record-subtype';
  constructor(private http: HttpClient) { }

  getAll(recordTypeName: string, filters = {}, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    const API_URL = `${RECORDTYPES_API_URL}/tenant/tenant-id/${tenantId}/records-type/record-type-name`;

    return this.http.get<Array<any>>(
      `${API_URL}/${recordTypeName}/${this.SUBTYPES_ROUTE}`, {
      params: filters
    });
  }

  get$(recordTypeName: string, recordSubtypeName: string, filters = {}, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    const API_URL = `${RECORDTYPES_API_URL}/tenant/tenant-id/${tenantId}/records-type/record-type-name`;

    return this.http.get<Array<any>>(
      `${API_URL}/${recordTypeName}/${this.SUBTYPES_ROUTE}/record-subtype-name/${recordSubtypeName}`, {
      params: filters
    });
  }

  create(recordTypeName: string, newRecordSubType, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    const API_URL = `${RECORDTYPES_API_URL}/tenant/tenant-id/${tenantId}/records-type/record-type-name`;

    return this.http.post(`${API_URL}/${recordTypeName}/${this.SUBTYPES_ROUTE}`, newRecordSubType);
  }

  update$(recordTypeName: string, recordSubtypeName: string, updatedSubtype, tenantId?: string) {
    if (!tenantId) { tenantId = environment.SD_TENANT_ID; }
    const API_URL = `${RECORDTYPES_API_URL}/tenant/tenant-id/${tenantId}/records-type/record-type-name`;

    return this.http.put(`${API_URL}/${recordTypeName}/${this.SUBTYPES_ROUTE}/record-subtype-name/${recordSubtypeName}`,
      updatedSubtype);
  }
}
